import React, { useEffect, useState } from 'react'

import classes from './Forms.module.css'
import Input from './Input'
import { RequiredError } from './ExtendedForm'
import Button from '../Button/Button'
import CheckIcon from '../../images/check_circle_outline-24px.svg'
import ToolTip from '../ToolTip/ToolTip'
import axios from 'axios'
import Select from './Select'


const red = '#ff6d6b'

const formDataDefaults = {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    domaine: 'Psychiatrie (Diagnostic/Médication)',
    message: ''
}

const errorsDefault = {
    firstNameError: false,
    lastNameError: false,
    emailError: false,
    phoneNumberError: false,
    domaineError: false,
    messageError: false
}

export default function (props) {

    const [isLoading, setIsLoading] = useState(false)
    const [formData, setFormData] = useState({...formDataDefaults})
    const [errors, setErrors] = useState({...errorsDefault})
    const [serverError, setServerError] = useState(false)
    const [success, setSuccess] = useState(false)

    useEffect(() => {
        console.log('props: ', props)
        if(props.formData) {
            setFormData({...props.formData})
        }
    }, [])

    const handleFormChanged = e => {
        setFormData({...formData, [e.target.name]: e.target.value})
    }

    const handleJoinWaitlist = () => {
        setIsLoading(true)
        setServerError(false)
        setErrors({...errorsDefault})
        setTimeout(() => {
            if (checkErrors()) {
                setIsLoading(false)
            } else {
                console.log('sending this data: ', {...formData})
                const API_URL  = 'https://us-central1-psy-scan-1f213.cloudfunctions.net/psyscan/waitlist'
                // const API_URL  = 'http://localhost:5001/psy-scan-1f213/us-central1/psyscan/waitlist'
                axios.post(API_URL, {...formData})
                .then(() => {
                    setIsLoading(false)
                    setSuccess(true)
                    if(window.gtag) {
                        window.gtag('event', 'submit', {
                            'event_category': "Form",
                            'event_label' : 'waitlist'
                        });
                    }
                })
                .catch(err => {
                    // console.log('err: ', err)
                    setIsLoading(false)
                    setServerError(true)
                })
            }
        }, 2000)
    }

    const checkErrors = () => {
        const error = { ...errorsDefault }
        let isError = false
        for( const [key, value] of Object.entries(formData)) {
            if(!value) {
                isError = true
                for( const [sKey, sValue] of Object.entries(errors)) {
                    if(sKey.includes(key)) {
                        error[sKey] = true
                    }
                }
            }
        }

        setErrors({...error})
        return isError
    }

    return(
        <div>
            <div className={classes.FormContainer} style={{boxShadow:'0 0 4px rgba(0,0,0,0.5)'}}>
                <div className={classes.ExtendedFormHeaderContainer} style={{display: 'flex', alignItems: 'center', fontSize:'1.1rem', height: 60}}>
                    Formulaire de liste d'attente
                </div>
                {!success && <div style={{padding: 20, color: red}}>
                    <b>Malheureusement nous n'avons plus de disponibilité, cependant nous pouvons vous contacter quand une place se libère. Si cela vous intéresse, veuillez remplir le formulaire suivant.</b>  
                </div>}
                
                {!success && <div style={{fontSize: '1rem'}}>
                    <div style={{padding: '0 24px'}}>
                        <div className={classes.FormRowWrapper}>
                            <div className={classes.FormRow}>
                                <div className={classes.Label}>Prénom</div>
                                <Input value={formData.firstName} name="firstName" changed={handleFormChanged} />
                                {errors.firstNameError ? <RequiredError/> : null}
                            </div>
                            <div className={classes.FormRow}>
                                <div className={classes.Label}>Nom de famile</div>
                                <Input value={formData.lastName} name="lastName" changed={handleFormChanged} />
                                {errors.lastNameError ? <RequiredError/> : null}
                            </div>
                        </div>
                    </div>
                    <div style={{padding: '0 24px'}}>
                        <div className={classes.FormRowWrapper}>
                            <div className={classes.FormRow}>
                                <div className={classes.Label}>Email</div>
                                <Input value={formData.email} name="email" changed={handleFormChanged} />
                                {errors.emailError ? <RequiredError/> : null}
                            </div>
                            <div className={classes.FormRow}>
                                <div className={classes.Label}>Téléphone</div>
                                <Input value={formData.phoneNumber} name="phoneNumber" changed={handleFormChanged} />
                                {errors.phoneNumberError ? <RequiredError/> : null}
                            </div>
                        </div>
                    </div>
                    <div className={classes.DomaineWrapper}>
                        <div className={classes.FormRowWrapper}>
                            <div style={{width:'100%'}}>
                                <div className={classes.Label}>Domaine de consultation</div>
                                <Select name="domaine" value={formData.domaine} changed={handleFormChanged} styles={{width: '100%'}}>
                                    <option value="Psychiatrie (Diagnostic/Médication)">Psychiatrie (Diagnostic/Médication)</option>
                                    <option value="Psychothérapie/ Consultation Individuelle">Psychothérapie/ Consultation Individuelle</option>
                                    <option value="Thérapie de couple">Thérapie de couple</option>
                                    <option value="Thérapie familiale">Thérapie familiale</option>
                                    <option value="Groupe thérapeutique">Groupe thérapeutique</option>
                                    <option value="Je ne sais pas ...">Je ne sais pas ...</option>
                                </Select>
                            </div>
                        </div>
                    </div>

                    <div style={{padding: '12px 24px 10px'}}>
                        <div className={classes.Label}>Pour quelle(s) raison(s) souhaitez-vous consulter?</div>
                            <textarea rows="6"
                              style={{width:'100%', borderRadius: 2}}
                              className={classes.TextArea} name="message" value={formData.message} onChange={handleFormChanged}>
                               </textarea>
                        {errors.messageError ? <RequiredError/> : null}
                    </div>
                </div>}

                {!success && <div style={{padding: '20px 24px 36px'}}>
                    <Button text="Soumettre" color="primary" clicked={handleJoinWaitlist} loading={isLoading}/>
                </div>}
                {serverError && <div style={{padding: '0 24px 36px'}}>
                    <div style={{color: red, backgroundColor: '#ffe4e6', padding: 10, borderRadius: 3, border:`1px solid ${red}`}}>
                        Une erreur s'est produite et nous n'avons pas reçu vos informations. Veuillez re-éssayer, mais si l'erreur persite appelez nous : <a href="tel:+41582010611"><b>058 201 06 11</b></a>.
                    </div>
                </div>}

                {success && <ThankYou />}
            </div>
        </div>
    )
}

const ThankYou = () => (
    <div style={{padding: 20}}>

        <div style={{padding: 20}}>
            <div style={{display: 'flex', justifyContent:'center', alignItems: 'center', width: '100%'}}>
                <img src={CheckIcon} alt="Icon Success" style={{width: 40, height: 40}}/>
            </div>
        </div>
        <p>
            Vos informations ont été soumises avec succès.
        </p>
        <p>
            La prochaine étape consiste à attendre notre appel. Nous vous contacterons dès qu'une disponibilité se presentera.
        </p>
        <p>
            En fixant un rendez-vous, vous <strong><ToolTip fontSize="0.8rem">acceptez notre politique d'annulation de 48 heures</ToolTip></strong>, c'est pour le premier rendez-vous seulement, par la suite les conditions du praticien s'appliquent.
        </p>
        <p>
            Si vous avez des questions n'hésitez pas à nous contacter au <a href="tel:+41582010611" style={{textDecoration: 'underline', fontWeight: 'bold'}}>058 201 06 11</a> du <strong>Lundi
            au Vendredi de 9h à 12h15 et 13h30 à 17h</strong>.
        </p>
        <p>
            <strong>Nous avons hâte de vous aider.</strong>
        </p>
        <style jsx>{`
        
            p {
                font-size: 1rem;
            }
        `}</style>
    </div>
)
